.container-body{
  padding-bottom: 5rem ;
}
.container-header{
  width: 100%;
  display: flex;
  text-align: center;
  justify-content: center;
}
.container-header h1{
  font-size: 3rem;
  font-weight: 600;
  text-align: center;
  font-family: var(--font-family);
  margin-bottom: 1rem;
}
.container-header p{
  font-size: 3rem;
  font-weight: 600;
}
.cont-img{
  display: flex;
  justify-content: center;
  align-items: center;
}
.course-img{
  width: 70%;

  text-align: center;
  margin: 0 auto;
  object-fit: cover;
}
.container-body{
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0 auto;
  color: var(--color-text);
  font-family: var(--font-family);
  text-align: justify;
}
.container-body p{
  margin-top: 1.5rem;
}
@media (max-width: 450px) {
  .container-header h1{
    font-size: 2rem;
    width: 80%;
  }
}