.instructora__banner{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-left: 2rem;
}
.gridd{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 100%;
    margin: 0 auto;
    margin-bottom: 5rem;
}
.instructora__banner img{
  width: 100%;
}
.instructora-content{
    display: flex;
    flex-direction: column;
    width: 90%;
    margin: 0 auto;
    margin-top: 3rem;
  }
.instructora__title h1{
    font-size: 3rem;
    margin-bottom: 1rem;
    font-weight: 600;
    font-family: var(--font-family);
}
.instructora__title p{
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 1rem;
  color: #71E5FF;
}

.instructora__text p{
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 1rem;
  color: #71E5FF;
}

@media screen and (max-width: 1024px){
  .gridd{
    grid-template-columns: repeat(1, 1fr);
  }
  .instructora__banner{
    padding-right: 3rem;
    padding-left: 3rem;
  }
}
@media screen and (max-width: 1024px){
  .instructora__banner{
    padding-right: 1rem;
    padding-left: 1rem;
  }
  .instructora__title h1{
    font-size: 2rem;
    margin-top: .3rem;
  }
  .instructora__title p{
    font-size: .9rem;
  }
  .instructora__text p{
    font-size: .9rem;
  }

}