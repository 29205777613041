* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

html{
  scroll-behavior: smooth;
}

body {
  background: var(--color-bg);
}

a {
  color: unset;
  text-decoration: none;
}

.gradient__bg {
  background: -moz-radial-gradient(circle at 3% 25%, rgba(0, 40, 83, 1) 0%, rgba(4, 12, 24, 1) 100%);
  background: -webkit-radial-gradient(circle at 3% 25%, rgba(0, 40, 83, 1) 0%, rgba(4, 12, 24, 1) 100%);
  background: -o-radial-gradient(circle at 3% 25%, rgba(0, 40, 83, 1) 0%, rgba(4, 12, 24, 1) 100%);
  background: -ms-radial-gradient(circle at 3% 25%, rgba(0, 40, 83, 1) 0%, rgba(4, 12, 24, 1) 100%);
  background: radial-gradient(circle at 3% 25%, rgba(0, 40, 83, 1) 0%, rgba(4, 12, 24, 1) 100%);
}

.gradient__text {
  background: var(--gradient-text);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.brand-title{
  font-size: 2rem;
  font-weight: 700;
  color: var(--color-text);
  text-transform: uppercase;
  letter-spacing: 1px;
  text-align: center;
  margin: 0 0 0 1rem;
}
@media screen and (max-width: 500px) {
  .brand-title{
    font-size: 1.2rem;
  }
}
.title-brand{
  font-family: manrope, sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 4rem 0;
}
.section__padding {
  padding: 4rem 6rem;
}
.section__padding-header{
  padding: 0 6rem 3rem 6rem;
}
@media screen and (max-width: 768px) {
  .section__padding-header{
    padding: 0 1.6rem 2rem 1.6rem;
  }
}
.section__margin {
  margin: 4rem 6rem;
}

.scale-up-center {
  -webkit-animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

@-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@media screen and (max-width: 700px) {
  .section__padding {
    padding: 4rem;
  }

  .section__margin {
    margin: 4rem;
  }
}

@media screen and (max-width: 550px) {
  .section__padding {
    padding: 4rem 2rem;
  }

  .section__margin {
    margin: 4rem 2rem;
  }
}
.testimonial-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  padding: 50px 0;
}

.testimonial-container h1 {
  font-family: var(--font-family);
  font-size: 3rem;
}

.testimonials-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  margin: 0 2rem;
  margin-top: 40px;
}

.testimonial-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 15px;
  color: aliceblue;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.1);
  transition: all 0.3s ease;
}

.testimonial-item h3 {
  font-family: var(--font-family);
  font-size: 1.5rem;
  margin: 10px 0;
}

.testimonial-item p {
  font-family: var(--font-family);
  font-size: 1rem;
  text-align: center;
}

.testimonial-item:hover {
  transform: scale(1.02);
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.3);
}


@media screen and (max-width: 768px) {
  .testimonials-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 500px) {
  .testimonials-grid {
    grid-template-columns: 1fr;
  }
}

.testimoniobutton {
  background: var(--gradient-text);
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  margin-top: 3rem;
  font-family: var(--font-family);
}