.gpt3__features-container__feature {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: row;
  margin: 1rem;
}
.gpt3__whatgpt3-container a{
  margin: 0 .5rem;
}
.gpt3__features-container__feature-title {
  max-width: 220px;
  margin-right: 2rem;
}
.gpt3__features-container__feature-title h1 {
  font-family: var(--font-family);
  font-weight: 700;
  font-size: 24px;
  margin-top: .5rem;
  line-height: 24px;
  letter-spacing: -0.03em;
  color: #fff;
}

.gpt3__features-container__feature-title div {
  height: 3px;
  width: 50px;
  background: var(--gradient-bar);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin-bottom: 0.25rem;
}

.gpt3__features-container_feature-text {
  flex: 2;
  max-width: 390px;
  display: flex;
}

.gpt3__features-container_feature-text p {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: var(--color-text);
}

@media screen and (max-width: 550px) {
  .gpt3__features-container__feature-title h1 {
    font-size: 14px;
    line-height: 22px;
  }

  .gpt3__features-container_feature-text p {
    font-size: 12px;
    line-height: 20px;
  }

  .gpt3__features-container__feature {
    margin: 1rem 0;
  }
}