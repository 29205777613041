.msdata{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
  padding: 0rem 6rem 3rem 6rem;
}

.msdata__container{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 70%;
  font-family: var(--font-family);
}
.msdata__container h1{
  font-size: 3rem;
  font-weight: 600;
  text-align: center;
  margin-bottom: 1rem;
}
.msdata__container p{
  margin: 2rem 0;
  font-size: 1rem;
  color: var(--color-text);
  text-align: justify;
  margin-bottom: 1rem;
}
.msdata__container img{
  width: 60%;
  object-fit: cover;
  margin-bottom: 1rem;
}
@media screen and (max-width: 768px){
  .msdata__container{
    width: 100%;
  }
  .msdata__container img{
    width: 90%;
  }
}
@media screen and (max-width: 500px){
  .msdata{
    padding: 2rem;
  }
  .msdata__container{
    width: 100%;
  }
  .msdata__container h1{
    font-size: 2rem;
  }
  .msdata__container img{
    width: 100%;
  }
}
.frase{
  margin-top: 2rem;
  font-size: 2rem;
  font-family: var(--font-family);
  font-weight: 600;
  text-align: center;
}