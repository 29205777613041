.testimonial-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  padding: 50px 0;
}

.testimonial-container h1 {
  font-family: var(--font-family);
  font-size: 3rem;
}

.testimonials-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  margin: 0 2rem;
  margin-top: 40px;
}

.testimonial-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 15px;
  color: aliceblue;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.1);
  transition: all 0.3s ease;
}

.testimonial-item h3 {
  font-family: var(--font-family);
  font-size: 1.5rem;
  margin: 10px 0;
}

.testimonial-item p {
  font-family: var(--font-family);
  font-size: 1rem;
  text-align: center;
}

.testimonial-item:hover {
  transform: scale(1.02);
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.3);
}


@media screen and (max-width: 768px) {
  .testimonials-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 500px) {
  .testimonials-grid {
    grid-template-columns: 1fr;
  }
}